// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.conditions {
    font-style: normal;
    font-family: "Ubuntu Condensed", sans-serif;
}

.conditions p{
    text-align:center;
    font-size: 30px;
}

.conditions div{
    font-size: 18px;
    margin: 10px 0;
}

.conditions .green{
    color: rgb(27, 135, 27);
}

.conditions .red{
    color: rgb(189, 13, 13);
}

.conditions-border {
    border-bottom: 2px solid #000000;
}
@media screen and (max-width:550px){
    .important-text {
        width: 100px;
        text-align:left;
        margin: 0 50px 0 -15px;
        font-size: 15px;
    }
}

@media screen and (min-width:550px){
    .important-text {
        width: 200px;
    }
}`, "",{"version":3,"sources":["webpack://./src/css/index-con-pol.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,2CAA2C;AAC/C;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,gCAAgC;AACpC;AACA;IACI;QACI,YAAY;QACZ,eAAe;QACf,sBAAsB;QACtB,eAAe;IACnB;AACJ;;AAEA;IACI;QACI,YAAY;IAChB;AACJ","sourcesContent":[".conditions {\n    font-style: normal;\n    font-family: \"Ubuntu Condensed\", sans-serif;\n}\n\n.conditions p{\n    text-align:center;\n    font-size: 30px;\n}\n\n.conditions div{\n    font-size: 18px;\n    margin: 10px 0;\n}\n\n.conditions .green{\n    color: rgb(27, 135, 27);\n}\n\n.conditions .red{\n    color: rgb(189, 13, 13);\n}\n\n.conditions-border {\n    border-bottom: 2px solid #000000;\n}\n@media screen and (max-width:550px){\n    .important-text {\n        width: 100px;\n        text-align:left;\n        margin: 0 50px 0 -15px;\n        font-size: 15px;\n    }\n}\n\n@media screen and (min-width:550px){\n    .important-text {\n        width: 200px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
