const color = [
    [//0 
        ''],
    //0 не учитывается 

    [//1 
        'Разноцветный'],
    [//2 
        'Красный'],
    [//3
        'Оранжевый'],
    [//4
        'Жёлтый'],
    [//5
        'Зеленый'],
    [//6
        'Голубой'],
    [//7
        'Синий'],
    [//8
        'Бирюзовый'],
    [//9
        'Розовый'],
    [//10
        'Бежевый'],
    [//11
        'Коричневый'],
    [//12
        'Серый'],
    [//13
        'Белый'],
]

export default color