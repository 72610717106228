const category = [
'',
'Джемперы',
'Кардиганы',
'Сумки',
'Шапки - скоро',
'Шарфы - скоро',
'Жилеты',
'Худи',
'Свитера',
]

export default category
