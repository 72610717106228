import React, { Component } from 'react'

export class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {
        return (
            <>
                <footer>«SoftSeason&copy;» все права защищены.
                </footer>
            </>
        )
    }
}

export default Footer