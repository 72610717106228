import React, { Component } from 'react';

class Politics extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      alert('Информация скопирована в буфер обмена!');
    } catch (err) {
      console.error('Ошибка копирования в буфер обмена:', err);
      alert('Не удалось скопировать информацию.');
    }
  }

  render() {
    return (
      <>
        <div className='conditions' >
            <p className='conditions-border'>Политика конфиденциальности и обработки персональных данных сайта «SoftSeason»</p>
            <div>{/*-----------------------*/}</div>
            <div style={{textAlign:"justify"}}>&nbsp;&nbsp;&nbsp;&nbsp;Настоящая Политика конфиденциальности и обработки персональных данных (далее - "Политика")
            разработана в соответствии Федеральным законом "О персональных данных" от 27.07.2006 N 152-ФЗ и
            применяется к всем персональным данным, которые собираются или обрабатываются на сайте интернет-
            магазина «SoftSeason» (далее - "Сайт"), включая данные, собранные через, электронную почту, или другие
            каналы связи.
            </div>
            <div className='conditions-border'>{/*-----------------------*/}</div>
            <div style={{fontWeight:"bold"}}>1.Сбор и использование персональных данных.</div>
            <div>{/*-----------------------*/}</div>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Мы можем собирать персональные данные наших клиентов, такие как:</div>
            <div>{/*-----------------------*/}</div>
            <div>
                <ul>
                    <li>ФИО;</li>
                    <li>Адрес;</li>
                    <li>Адрес электронной почты;</li>
                    <li>Номер телефона;</li>
                    <li>Размеры одежды;</li>
                    <li>Другие сведения необходимые в целях обработки заказа.</li>
                </ul>
            </div>
            <div>{/*-----------------------*/}</div>
            <div style={{textAlign:"justify"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Сбор указанных данных осуществляется только при вашем согласии и в целях обработки заказов,
            предоставления услуг поддержки клиентов, рассылки информации о наших товарах и акциях.
            </div>
            <div className='conditions-border'>{/*-----------------------*/}</div>
            <div style={{fontWeight:"bold"}}>2.Обработка персональных данных</div>
            <div>{/*-----------------------*/}</div>
            <div style={{textAlign:"justify"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Мы обязуемся обрабатывать персональные данные наших клиентов в соответствии с применимым
            законодательством о защите персональных данных и принимать все необходимые меры для обеспечения их
            безопасности. Мы не раскрываем ваши персональные данные третьим лицам без вашего согласия, за
            исключением случаев, предусмотренных законом.</div>
            <div className='conditions-border'>{/*-----------------------*/}</div>
            <div style={{fontWeight:"bold"}}>3.Хранение и защита данных</div>
            <div >{/*-----------------------*/}</div>
            <div style={{textAlign:"justify"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Мы храним ваши персональные данные только в течение необходимого периода времени в целях
            обработки заказов и предоставления услуг продажи, а также информационной поддержки клиентов. Мы принимаем
            технические и организационные меры для защиты ваших персональных данных от несанкционированного доступа, утраты или уничтожения.
            </div>
            <div className='conditions-border'>{/*-----------------------*/}</div>
            <div style={{fontWeight:"bold"}}>4.Права субъектов данных</div>
            <div>{/*-----------------------*/}</div>
            <div style={{textAlign:"justify"}}>&nbsp;&nbsp;Вы имеете право запросить доступ к своим персональным данным, в целях исправления неточных
            сведений, удаления данных, ограничение обработки и возражение против обработки в соответствии с
            применимым законодательством. Вы также можете в любое время отозвать свое согласие на обработку
            персональных данных.</div>
            <div className='conditions-border'>{/*-----------------------*/}</div>
            <div style={{fontWeight:"bold"}}>5.Изменения в политике</div>
            <div>{/*-----------------------*/}</div>
            <div style={{textAlign:"justify"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Интернет-магазин оставляет за собой право вносить изменения в настоящую Политику. Любые
            изменения будут опубликованы на нашем веб-сайте, и ваше продолженное использование наших услуг после
            внесения изменений будет означать ваше согласие с такими изменениями.</div>
            <div className='conditions-border'>{/*-----------------------*/}</div>
            <div style={{fontWeight:"bold"}}>6.Правила принятия и согласия с настоящей Политикой конфиденциальности и обработки
            персональных данных.
            </div>
            <div>{/*-----------------------*/}</div>
            <div style={{textAlign:"justify"}}>&nbsp;&nbsp;Осуществляя заказ на веб-сайте «SoftSeason», покупатель подтверждает свое согласие на сбор и
            обработку персональных данных, а также выражает свое согласие с правилами обработки персональных
            данных и Политикой конфиденциальности.</div>
        </div>
      </>
    )
  }
}

export default Politics;
