const material = [
    [//0 
        ''],
    //0 не учитывается 

    [//1 
        'Пух норки'],
    [//2
        'Шерсть верблюжёнка'],
    [//3 
        'Беби альпака'],
    [//4 
        'Шерсть акрилл'],
    [//5
        'Шерсть'],
    [//6
        'Мохер'],
    [//7
        'Пайетки на хлопке'],
    [//8
        'Хлопок'],
    [//9
        'Хлопок лорекс'],
    [//10
        'Альпака'],
    [//11
        'Пайетки'],
]

export default material