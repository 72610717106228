const title = [
    '',
    'Артикул_№-0001',
    'Артикул_№-0002',
    'Артикул_№-0003',
    'Артикул_№-0004',
    'Артикул_№-0005',
    'Артикул_№-0006',
    'Артикул_№-0007',
    'Артикул_№-0008',
    'Артикул_№-0009',
    'Артикул_№-0010',
    'Артикул_№-0011',
    'Артикул_№-0012',
    'Артикул_№-0013',
    'Артикул_№-0014',
    'Артикул_№-0015',
    'Артикул_№-0016',
    'Артикул_№-0017',
    'Артикул_№-0018',
    'Артикул_№-0019',
    'Артикул_№-0020',
    'Артикул_№-0021',
    'Артикул_№-0022',
    'Артикул_№-0023',
    'Артикул_№-0024',
    'Артикул_№-0025',
    'Артикул_№-0026',
    'Артикул_№-0027',
    'Артикул_№-0028',
    'Артикул_№-0029',
    'Артикул_№-0030',
    'Артикул_№-0031',
    'Артикул_№-B001',
    'Артикул_№-B002',
    'Артикул_№-B003',
    'Артикул_№-B004',
    'Артикул_№-Shoper-01'
];

export default title;
