const price = [
    [//0
        ''],
    //not count \

    [//1
        '19999'],
    [//2 
        '19999'],
    [//3
        '19999'],
    [//4
        '11999'],
    [//5
        '17999'],
    [//6
        '16999'],
    [//7
        '16999'],
    [//8
        '16999'],
    [//9
        '19999'],
    [//10
        '9499'],
    [//11
        '16999'],
    [//12
        '14999'],
    [//13
        '14999'],
    [//14
        '16999'],
    [//15
        '16999'],
    [//16
        '6999'],
    [//17
        '14999'],
    [//18
        '16999'],
    [//19
        '16999'],
    [//20
        '14999'],
    [//21
        '6999'],
    [//22
        '11999'],
    [//23
        '10499'],
    [//24
        '16499'],
    [//25
        '14999'],
    [//26
        '16999'],
    [//27
        '14999'],
    [//28
        '9900'],
    [//29
        '9499'],
    [//30
        '9900'],
    [//31
        '9900'],
    [//Сумка-1
        '10499'],
    [//Сумка-2
        '10499'],
    [//Сумка-3
        '10499'],
    [//Сумка-4
        '10499'],
    [//Шопер-1
        '6999'],
]

export default price