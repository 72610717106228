import React, { Component } from 'react';

class Conditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.targetRef = React.createRef();
    this.copyToClipboardRef = React.createRef();
  }


  copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText('softseason.refusal@yandex.ru');
      alert('Адрес электронной почты скопирован в буфер обмена!');
    } catch (err) {
      console.error('Ошибка копирования в буфер обмена:', err);
      alert('Не удалось скопировать адрес электронной почты.');
    }
  }

  handleScrollToTarget = () => {
    this.targetRef.current.scrollIntoView({ behavior: "smooth" });
  }

  render() {
    return (
        <>
        <div className='conditions'>
          <p className='conditions-border'>Условия купли-продажи, заказа и возврата товаров на сайте «SoftSeason&copy;».</p>
          <div style={{textAlign:"justify"}}>
          <div style={{fontWeight:"bold", cursor:"pointer"}} onClick={this.handleScrollToTarget}>Товар <span className='red'>надлежащего</span> качества – возврату не подлежит! ¹</div>
          <div style={{fontWeight:"bold"}}>Швейные и трикотажные изделия обмену <span className='red'>не подлежат</span>!</div>
          <div><span style={{fontWeight:"bold"}}>Без примерки.</span> (на сайте представлено подробное описание товаров с их размерами и мерками, просим отнестись с пониманием к данному
          требованию с учетом индивиppдуальности и деликатности товара)
          </div>
          <div>Весь ассортимент товара ручной работы, выполнен в единственном экземпляре;</div>
          <div>Все товары выполнены из высококачественного материала.</div>
          <div className='conditions-border'></div>
          <div>На настоящем сайте представлены трикотажные изделия ручной работы, которые в соответствии с Перечнем, утв. Постановлением
          Правительства РФ №2463 обмену не подлежат.
          </div>
          <div>Весь ассортимент товара представлен в единственном экземпляре, в случае отсутствия товара, индивидуальные условия пошива (вязки)
          согласовываются по средством составления Покупателем формы обратной связи.<span style={{fontWeight:"bold"}}>Обращаем Ваше внимание, что вещи индивидуальной
          вязки, связанные на заказ, обмену-возврату не подлежат.</span> 
          </div>
          <div><span style={{fontWeight:"bold"}}>Продажа товара осуществляется дистанционным способом,</span> это означает, что договор розничной купли-продажи заключается на основании
          ознакомления покупателя с предложенным Продавцом описанием товара, размещенным на настоящем сайте.</div>
          <div>Доставка товаров осуществляется следующими логистическими службами: СДЭК, Boxbery, PickPoint. Доставка осуществляется в
          следующем порядке:
          <div>{/*-----------------------*/}</div>
          <ul style={{marginLeft:"20px", marginRight:"20px" }}>
            <li>Отправка товара осуществляется по 100% предоплате товара;</li>
            <li>Отправка осуществляется в день заказа (при условии поступления заказа до 15:00), при заказе после 15:00, отправка осуществляется на
            следующий день. Отправка осуществляется с учетом графика работы логистических служб. Продавец не несет ответственность за сроки
            и качество доставки логистическими службами.</li>
          </ul>
          </div>
          <div className='conditions-border'>{/*-----------------------*/}</div>
          <div style={{fontWeight:"bold"}}>Отказ от заказанного дистанционным способом товара надлежащего качества и его возврат Продавцу допустим в следующих
          случаях:
          </div>
          <div>{/*-----------------------*/}</div>
          <ul style={{marginLeft:"20px", marginRight:"20px" }}>
            <li>в любое время до его передачи Покупателю;</li>
            <li>в течение <span style={{fontWeight:"bold"}}>семи дней</span> после передачи товара без объяснения причин.</li>
          </ul>
          <div className='conditions-border'>{/*-----------------------*/}</div>
          <div>Возврат уже полученного товара, при отказе от него в указанный семидневный срок, осуществляется после предварительного направления
          Покупателем сообщения на адрес электронной <span style={{color:"blue", cursor:"pointer"}} onClick={this.copyToClipboard}>softseason.refusal@yandex.ru</span>, в сообщении указываются:
        </div>
        <div>{/*-----------------------*/}</div>
          <ul style={{marginLeft:"20px", marginRight:"20px" }}>
            <li>ФИО Покупателя,</li>
            <li>Дата покупки,</li>
            <li>Артикул товара,</li>
            <li>Реквизиты для возврата денежных средств,</li>
            <li>Контактные данные (номер телефона).</li>
            <li><span style={{fontWeight:"bold", cursor:"pointer"}} onClick={this.handleScrollToTarget}>Также к сообщению прикладываются <span className='red'>подробная фотофиксация состояния товара</span> ² </span>, в целях установления Продавцом сохранности
            товарного вида. <span style={{fontWeight:"bold"}}>При установлении Продавцом отсутствия сохранности товарного вида и потребительских свойств, Продавец
            оставляет за собой право отказать Покупателю в возврате переданного товара и денежных средств, никакие претензии в данном
            случае в адрес Продавца не принимаются и не рассматриваются.</span></li>
          </ul>
          <div className='conditions-border'>{/*-----------------------*/}</div>
        <div>Срок рассмотрения заявки при отказе от товара составляет 3-5 рабочих дня. При одобрении Продавцом возврата, в случае отказа от товара,
        Покупателю направляется письмо с инструкцией по возврату. Товар надлежит упаковать в герметичную упаковку, которая позволит сохранить
        целостность Товара в процессе его транспортировки, (если сохранилась продажная упаковка, можно упаковать в нее), товар подлежит отправке
        в адрес продавца следующими логистическими службами: СДЭК, Boxbery, PickPoint. <span style={{fontWeight:"bold"}}>Расходы по отправке товара Продавцу, в случае
        отказа от товара, лежат на стороне Покупателя.</span>
        </div>
        <div className='conditions-border'></div>
        <div><span style={{fontWeight:"bold"}}>Возврат денежных средств:</span> если товар, в отношении которого поступил отказ Покупателя - <span style={{fontWeight:"bold"}}>надлежащего качества</span>, и все
        вышеперечисленные условия соблюдены, возврат денежных средств осуществляется по реквизитам счета указанным покупателем в
        сообщении, либо через систему СБП, <span style={{fontWeight:"bold"}}>в течении 10 дней с момента получения товара Продавцом</span>. Обращаем внимание, Продавец не несет
        ответственность и не отвечает за работу Банковских систем и за сроки и качество доставки логистическими службами товара.
        </div>
        <div>Обращаем внимание: вернуть продавцу товар в указанные сроки можно, если: <span className='red'>сохранены товарный вид, потребительские свойства товара.</span></div>
        <div style={{fontWeight:"bold"}}>Обращаем внимание, что при осуществлении отправки каждого индивидуального товара осуществляется его фото-видео сьемка в
        целях фиксации состояния и качества.
        </div>
        <div className="red" style={{fontWeight:"bold"}}>Осуществляя заказ на сайте, Покупатель подтверждает, что он ознакомлен с настоящими Условиями и полностью их принимает.</div>
        </div>

        <div  className='conditions-border' style={{marginBottom:"20px"}}>{/*-----------------------*/}</div>
        
        <div style={{fontSize:"14px"}} ref={this.targetRef}>¹ - Потребитель вправе вернуть продавцу товар, приобретенный дистанционным способом в соответствии с условиями возврата.
        Если товар некачественный, то потребитель имеет те же права, что и при обычной покупке товара (п. 5 ст. 26.1 Закона N 2300-1).
        </div>
        <div>{/*-----------------------*/}</div>
        <div style={{fontSize:"14px"}}>² - Фото должно отражать состояние товара (вид с лицевой стороны, вид со спины, фото рукавов, манжет (если имеются), вид с изнанки. Фото должно быть высокой
четкости (не размытым), выполненным в высоком разрешении.
        </div>
        </div>
        </>
    )
  }
}

export default Conditions